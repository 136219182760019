import {
  chakra,
  forwardRef,
  ImageProps,
  keyframes,
  usePrefersReducedMotion,
} from '@chakra-ui/react'
import React from 'react'

import logo from './logo.svg'

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

export const Logo = forwardRef<ImageProps, 'img'>((props, ref) => {
  const prefersReducedMotion = usePrefersReducedMotion()

  const animation = prefersReducedMotion
    ? undefined
    : `${spin} infinite 20s linear`

  return <chakra.img ref={ref} animation={animation} src={logo} {...props} />
})
